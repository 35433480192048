import React from 'react';

const PrivacyPolicy: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Privacy Policy
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-6">
					<h2 className="text-3xl font-semibold">
						Personal Data Privacy Notice
					</h2>
					<p className="mb-4">
						This notice explains what information we collect, when we collect it
						and how we use this. During the course of our activities, we will
						process personal data (which may be held on paper, electronically,
						or otherwise) about you and we recognise the need to treat it in an
						appropriate and lawful manner. The purpose of this notice is to make
						you aware of how we will handle your information.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">Who am I?</h2>
					<p className="mb-4">
						Rochefort Shugar Ltd. [Residential Letting & Managing Agent] at 33
						Manor Rise, Whitchurch, Cardiff, CF14 1QH, (“I”/ “we” or “me”/ “us”)
						take the issue of security and data protection very seriously and
						strictly adhere to guidelines published in the General Data
						Protection Regulation (EU) 2016/679 which is applicable from the
						25th May 2018, together with any domestic laws subsequently enacted.
						We are notified as a Data Controller with the Office of the
						Information Commissioner under registration number [Z1551459] and we
						are the data controller of any personal data that you provide to us.
					</p>
					<p className="mb-4">
						Our Data Protection Officer is Mr Mark Shugar. Any questions
						relating to this notice and our privacy practices should be sent to
						Mr Mark Shugar by email at{' '}
						<a
							href="mailto:mark@rochefortshugar.com"
							className="text-blue-600 underline"
						>
							mark@rochefortshugar.com
						</a>{' '}
						or by mobile on{' '}
						<a href="tel:07958712750" className="text-blue-600 underline">
							07958 712750
						</a>
						.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						How we collect information from you and what information I collect
					</h2>
					<p className="mb-4">How we collect information about you:</p>
					<ul className="list-disc list-inside mb-4 space-y-2">
						<li>From your application for accommodation</li>
					</ul>
					<p className="mb-4">
						We collect the following information about you:
					</p>
					<ul className="list-disc list-inside mb-4 space-y-2">
						<li>
							Prospective contract holders and/or guarantor names, email
							address, date of birth, address (including any previous addresses,
							relationship to other prospective contract holders, employment
							status, name of university or college where you are studying (if
							applicable);
						</li>
						<li>
							Contract holder name, email address, telephone number, Date of
							Birth, address (including any previous addresses), marital status,
							National Insurance Number, nationality, next of kin, name of
							university or college where you are studying (if applicable), the
							name of friends that you are staying with (if applicable);
						</li>
						<li>
							Guarantor name, e-mail address, telephone number, Date of Birth,
							address (including any previous addresses), marital status,
							National Insurance Number, nationality, next of kin (if
							applicable);
						</li>
						<li>
							Property address; term, rent, deposit, utility and service
							responsibilities;
						</li>
						<li>
							The employment status of contract holder and/or guarantors,
							address, contact details (including email, phone and fax numbers)
							of the employer/accountant, payroll numbers, length of employment,
							salary information (including any regular overtime or commission),
							and any other income received;
						</li>
						<li>
							Bank account details of the contract holder and prospective
							contract holders, including account number and sort code, and any
							hire purchase/loan agreements/credit cards or store cards that you
							have; and
						</li>
						<li>
							Any welfare benefits that you may be eligible for, or are
							currently on.
						</li>
					</ul>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						Why we need this information about you and how it will be used
					</h2>
					<p className="mb-4">
						We need your information and will use your information:
					</p>
					<ul className="list-disc list-inside mb-4 space-y-2">
						<li>
							to undertake and perform our obligations and duties to you in
							accordance with the terms of our contract with you;
						</li>
						<li>
							to enable us to supply you with the services and information which
							you have requested;
						</li>
						<li>to help you to manage your contract;</li>
						<li>
							to carry out due diligence on any prospective contract holder
							and/or guarantor, including whether there is any money judgements
							against them, or any history of bankruptcy or insolvency;
						</li>
						<li>
							to analyse the information we collect so that we can administer,
							support and improve and develop our business and the services we
							offer;
						</li>
						<li>
							to contact you in order to send you details of any changes to our
							suppliers which may affect you; and
						</li>
						<li>
							for all other purposes consistent with the proper performance of
							our operations and business.
						</li>
					</ul>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						Sharing of Your Information
					</h2>
					<p className="mb-4">
						The information you provide to us will be treated by us as
						confidential and will be processed only by any third party, acting
						on our behalf, within the UK/EEA.
					</p>
					<p className="mb-4">
						We may disclose your information to other third parties who act for
						us for the purposes set out in this notice or for purposes approved
						by you, including the following:
					</p>
					<ul className="list-disc list-inside mb-4 space-y-2">
						<li>
							If we enter into a joint venture with or merge with a business
							entity, your information may be disclosed to our new business
							partners or owners;
						</li>
						<li>
							To carry out due diligence on you as a prospective contract
							holder/guarantor, including but not limited to the carrying out of
							affordability checks, due diligence checks and the obtaining of
							references from relevant parties, whose data you have provided;
						</li>
						<li>
							If you request so, your information shall be disclosed in order to
							determine if there are any money judgements against you, as the
							prospective contract holder/guarantor, or to determine if they
							have a history of bankruptcy or insolvency;
						</li>
						<li>
							If you are unable to make payments under your contract, your
							information may be disclosed to any relevant party assisting in
							the recovery of this debt or the tracing of you as a contract
							holder; and
						</li>
						<li>
							In the creation, renewal or termination of the contract, your
							information will be disclosed to the relevant local authority,
							tenancy deposit scheme administrator, service/utility provider,
							freeholder, factor, facilities manager or any other relevant
							person or organisation in connection with this.
						</li>
					</ul>
					<p className="mb-4">
						Unless required to do so by law, we will not otherwise share, sell
						or distribute any of the information you provide to us without your
						consent.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						Transfers outside the UK and Europe
					</h2>
					<p className="mb-4">
						Your information will only be stored within the UK and EEA.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">Security</h2>
					<p className="mb-4">
						When you give us information we take steps to make sure that your
						personal information is kept secure and safe. Paper records are
						stored in a lockable filing cabinet on our lockable premises and
						electronic records are stored in our business computer and business
						OneDrive which is password protected limiting access to ourselves
						only.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						How long we will keep your information
					</h2>
					<p className="mb-4">
						We review our data retention periods regularly and will only hold
						your personal data for as long as is necessary for the relevant
						activity, or as required by law (We may be legally required to hold
						some types of information), or as set out in any relevant contract I
						have with you. Our full retention schedule is available from
						ourselves on request.
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">Your Rights</h2>
					<p className="mb-4">You have the right at any time to:</p>
					<ul className="list-disc list-inside mb-4 space-y-2">
						<li>
							ask for a copy of the information about you held by us in our
							records;
						</li>
						<li>require us to correct any inaccuracies in your information;</li>
						<li>
							make a request to us to delete what personal data of yours we
							hold; and
						</li>
						<li>object to receiving any marketing communications from us.</li>
					</ul>
					<p className="mb-4">
						If you would like to exercise any of your rights above please
						contact us at{' '}
						<a
							href="mailto:mark@rochefortshugar.com"
							className="text-blue-600 underline"
						>
							mark@rochefortshugar.com
						</a>
						. Should you wish to complain about the use of your information, we
						would ask that you contact us to resolve this matter in the first
						instance. You also have the right to complain to the Information
						Commissioner’s Office in relation to our use of your information.
						The Information Commissioner’s contact details are noted below:
					</p>

					<h2 className="text-3xl font-semibold mt-8 mb-4">
						Contact Details for Information Commissioner's Office
					</h2>
					<div className="mb-4">
						<div className="flex flex-col md:flex-row md:space-x-6">
							<div className="md:w-1/2">
								<h3 className="text-2xl font-semibold mt-4">England:</h3>
								<p className="mb-2">
									Information Commissioner's Office
									<br />
									Wycliffe House, Water Lane
									<br />
									Wilmslow, Cheshire, SK9 5AF
									<br />
									Telephone:{' '}
									<a href="tel:03031231113" className="text-blue-600 underline">
										0303 123 1113
									</a>
									<br />
									Email:{' '}
									<a
										href="mailto:casework@ico.org.uk"
										className="text-blue-600 underline"
									>
										casework@ico.org.uk
									</a>
								</p>
							</div>
							<div className="md:w-1/2">
								<h3 className="text-2xl font-semibold mt-4">Scotland:</h3>
								<p className="mb-2">
									The Information Commissioner’s Office – Scotland
									<br />
									Queen Elizabeth House, Sibbald Walk
									<br />
									Edinburgh, EH8 8FT
									<br />
									Telephone:{' '}
									<a href="tel:03031231115" className="text-blue-600 underline">
										0303 123 1115
									</a>
									<br />
									Email:{' '}
									<a
										href="mailto:Scotland@ico.org.uk"
										className="text-blue-600 underline"
									>
										Scotland@ico.org.uk
									</a>
								</p>
							</div>
						</div>

						<div className="flex flex-col md:flex-row md:space-x-6 mt-6">
							<div className="md:w-1/2">
								<h3 className="text-2xl font-semibold mt-4">Wales:</h3>
								<p className="mb-2">
									Information Commissioner's Office
									<br />
									2nd floor, Churchill House
									<br />
									Churchill way, Cardiff, CF10 2HH
									<br />
									Telephone:{' '}
									<a href="tel:03304146421" className="text-blue-600 underline">
										0330 414 6421
									</a>
									<br />
									Email:{' '}
									<a
										href="mailto:wales@ico.org.uk"
										className="text-blue-600 underline"
									>
										wales@ico.org.uk
									</a>
								</p>
							</div>
							<div className="md:w-1/2">
								<h3 className="text-2xl font-semibold mt-4">
									Northern Ireland:
								</h3>
								<p className="mb-2">
									The Information Commissioner’s Office – Northern Ireland
									<br />
									10th Floor, Causeway Tower
									<br />
									9 James Street South
									<br />
									Belfast, BT2 8DN
									<br />
									Telephone:{' '}
									<a href="tel:03031231114" className="text-blue-600 underline">
										0303 123 1114
									</a>
									<br />
									Email:{' '}
									<a
										href="mailto:ni@ico.org.uk"
										className="text-blue-600 underline"
									>
										ni@ico.org.uk
									</a>
								</p>
							</div>
						</div>
					</div>

					<p className="mb-4">
						The accuracy of your information is important to us - please help us
						keep our records updated by informing us of any changes to your
						email address and other contact details.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
