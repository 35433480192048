import React from 'react';
import { Link } from 'react-router-dom';
import dps_logo from '../assets/dps_logo.png';
import nrla_logo from '../assets/nrla_logo.png';
import prs_logo from '../assets/prs_logo_high.jpg';
import rsw_logo from '../assets/rsw_logo.gif';

const Footer: React.FC = () => {
	return (
		<footer className="w-full bg-gray-900 text-white py-4">
			<div className="container mx-auto px-4">
				<div className="flex flex-col md:flex-row justify-between items-center">
					{/* Links Section */}
					<div className="mb-4 md:mb-0">
						<ul className="space-y-2 text-center md:text-left">
							<li>
								<Link
									to="/complaints-procedure"
									className="hover:text-blue-400 transition duration-300"
								>
									Complaints Procedure
								</Link>
							</li>
							<li>
								<Link
									to="/equality-and-diversity-policy"
									className="hover:text-blue-400 transition duration-300"
								>
									Equality & Diversity Policy
								</Link>
							</li>
							<li>
								<Link
									to="/privacy-policy"
									className="hover:text-blue-400 transition duration-300"
								>
									Privacy Policy
								</Link>
							</li>
						</ul>
					</div>

					{/* Copyright Section */}
					<div className="text-center text-sm text-gray-400 md:order-2">
						<p>© 2024 Rochefort Shugar. All rights reserved.</p>
					</div>

					{/* Social Media Links */}
					<div className="flex space-x-4 mt-4 md:mt-0">
						<a
							href="https://www.depositprotection.com/"
							target="_blank"
							rel="noopener noreferrer"
							className="hover:text-blue-500"
						>
							<img
								src={dps_logo}
								alt="Deposit Protection Service Logo"
								className="h-10"
							/>
						</a>
						<a
							href="https://www.nrla.org.uk/"
							target="_blank"
							rel="noopener noreferrer"
							className="hover:text-blue-400"
						>
							<img src={nrla_logo} alt="NRLA Logo" className="h-10" />
						</a>
						<a
							href="https://www.theprs.co.uk/"
							target="_blank"
							rel="noopener noreferrer"
							className="hover:text-pink-500"
						>
							<img src={prs_logo} alt="PRS Logo" className="h-10" />
						</a>
						<a
							href="https://rentsmart.gov.wales"
							target="_blank"
							rel="noopener noreferrer"
							className="hover:text-blue-700"
						>
							<img src={rsw_logo} alt="RSW Logo" className="h-10" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
