import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const ContactUs: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Contact Us
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-6">
					<p className="mb-6">
						We are here to assist you with any questions or concerns you may
						have. Please feel free to reach out to us through any of the methods
						below. Our team is dedicated to providing you with the best possible
						service.
					</p>
					<div className="space-y-4">
						<div className="flex items-center">
							<FaPhone className="text-blue-600 w-6 h-6 mr-3" />
							<span className="text-md">
								<a href="tel:07958712750" className="text-blue-600 underline">
									07958 712750
								</a>
							</span>
						</div>
						<div className="flex items-center">
							<FaEnvelope className="text-blue-600 w-6 h-6 mr-3" />
							<span className="text-md">
								<a
									href="mailto:mark@rochefortshugar.com"
									className="text-blue-600 underline"
								>
									mark@rochefortshugar.com
								</a>
							</span>
						</div>
						<div className="flex items-center">
							<FaMapMarkerAlt className="text-blue-600 w-6 h-6 mr-3" />
							<span className="text-md">
								33 Manor Rise, Whitchurch, Cardiff, CF14 1QH
							</span>
						</div>
					</div>
					<p className="mt-6">
						Our office hours are Monday to Friday, 9:00 AM to 5:00 PM. If you
						need to reach us outside of these hours, please send us an email or
						leave a voicemail, and we will get back to you as soon as possible.
					</p>
					<p>
						For immediate assistance, especially regarding urgent property
						matters, please use the mobile number provided above.
					</p>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
