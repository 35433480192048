import React from 'react';

const ComplaintsProcedure: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Complaints Procedure
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-6">
					{/* Introduction */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">Introduction</h2>
						<p className="mb-4">
							We at Rochefort Shugar Limited are committed to dealing
							effectively with any complaints you have about our service. If we
							have made a mistake, we will apologise and try to put things
							right. We review complaints regularly and use this information to
							improve the way we do things. We also welcome comments and
							compliments about the service we have provided.
						</p>
						<p>
							We want it to be simple and convenient for you to raise a
							complaint, make a comment, or pay us a compliment.
						</p>
					</section>

					{/* Expectations */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">
							What can you expect from us when you make a complaint?
						</h2>
						<p className="mb-4">
							If we receive a complaint from you, we will do all that we can to
							help you, and we will try to find a solution to any problems you
							have brought to our attention. We will ensure that:
						</p>
						<ul className="list-disc list-inside mb-4 space-y-2">
							<li>
								Your complaint is acknowledged within 3 working days of the date
								that it is received.
							</li>
							<li>
								We will aim to resolve the complaint within 15 working days. If
								your complaint is more complex, we may need more time.
							</li>
							<li>
								We will let you know within the 15 working day period if we
								think it may take longer and provide regular updates.
							</li>
						</ul>
						<p>
							We will respect your right to confidentiality and privacy and
							treat you fairly in accordance with our commitment to equality.
						</p>
					</section>

					{/* Outcome */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">Outcome</h2>
						<p className="mb-4">
							Following our investigation, we will let you know what we have
							found, using your preferred form of communication (such as letter
							or e-mail). We will explain how and why we came to our
							conclusions.
						</p>
						<p>
							If we find that we have done something wrong, we will tell you,
							explain what happened, and apologise. If we find there is a fault
							in our systems or the way we do things, we will let you know and
							explain how we plan to change things.
						</p>
					</section>

					{/* Putting things right */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">
							Putting things right
						</h2>
						<p>
							If there is a simple solution to your problem, we may ask you to
							accept this solution. For example, if you asked for a service and
							we see straight away that you should have had it, we will offer to
							provide the service rather than continue to investigate.
						</p>
					</section>

					{/* Staff training */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">Staff Training</h2>
						<p>
							We will make sure that our staff are trained to handle complaints
							effectively and receive appropriate training in the use of the
							Complaints Policy.
						</p>
					</section>

					{/* The Redress Scheme */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">The Redress Scheme</h2>
						<p className="mb-4">
							We are a member of the Property Redress Scheme. You can contact
							the Redress Scheme at any time. However, they are unable to deal
							with a complaint until our complaints process has been completed
							and we have had a chance to put things right.
						</p>
					</section>

					{/* Complaint Management Process */}
					<section className="mb-6">
						<h2 className="text-3xl font-semibold mb-4">
							Complaint Management Process
						</h2>
						<p className="mb-4">
							The process for resolving matters which have not been addressed to
							your satisfaction within agreed timescales or expectations is set
							out below:
						</p>

						<h3 className="text-2xl font-semibold mb-2">
							Stage 1 - Making your complaint
						</h3>
						<p className="mb-4">
							Please set out the details of your complaint in writing (by letter
							or email) by contacting Mark Shugar “Director” at:
						</p>
						<ul className="list-none mb-4 space-y-2">
							<li>
								<strong>Email:</strong>{' '}
								<a
									href="mailto:info@rochefortshugar.com"
									className="text-blue-600 underline"
								>
									info@rochefortshugar.com
								</a>
							</li>
							<li>
								<strong>Address:</strong> 33 Manor Rise, Whitchurch, Cardiff
								CF14 1QH
							</li>
							<li>
								<strong>Telephone number:</strong>{' '}
								<a href="tel:07958712750" className="text-blue-600 underline">
									07958 712 750
								</a>
							</li>
						</ul>

						<h3 className="text-2xl font-semibold mb-2">
							Stage 2 - Our acknowledgement
						</h3>
						<p className="mb-4">
							Your complaint will be acknowledged within 3 working days of us
							receiving it.
						</p>

						<h3 className="text-2xl font-semibold mb-2">
							Stage 3 - Our investigation
						</h3>
						<p className="mb-4">
							Your complaint will be investigated, and Mark Shugar “Director”
							will provide a formal written response within 15 working days of
							receiving your complaint.
						</p>

						<h3 className="text-2xl font-semibold mb-2">
							Stage 4 - Final Viewpoint
						</h3>
						<p className="mb-4">
							If you remain dissatisfied, we will conduct a separate review, and
							Mark Shugar “Director” will provide our final viewpoint on the
							matter.
						</p>

						<h3 className="text-2xl font-semibold mb-2">Stage 5 - Redress</h3>
						<p>
							If our final viewpoint letter does not resolve matters (or more
							than 8 weeks have passed), you can request an independent review
							from the Property Redress Scheme within 12 months of receiving our
							final letter.
						</p>
					</section>
				</div>
			</div>
		</div>
	);
};

export default ComplaintsProcedure;
