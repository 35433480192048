import React from 'react';

const AboutUs: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-2xl md:text-4xl font-bold text-gray-900 mb-8 text-center">
					About Us
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-6">
					<p className="mb-4">
						Rochefort Shugar is a family-run business established in 1996. Our
						policy is to provide a friendly, personalised, and comprehensive
						management service for landlords and contract holders. Our success
						can be measured by the continual growth of our portfolio of managed
						properties, created through referrals, recommendations, and
						reputation. We manage residential properties throughout Cardiff,
						Vale of Glamorgan, and West Wales.
					</p>
					<p className="mb-4">
						We can provide information on all new legislation relating to
						landlords and contract holders, gas safety checks, electrical
						regulations, fire safety regulations, and insurances. We can arrange
						EPC's on request. We also offer free property investment advice.
					</p>
					<p className="mb-4">
						Rochefort Shugar is a member of Rent Smart Wales (RSW), the National
						Association of Estate Agents (NRLA), the Property Redress Scheme
						(PRS), the Deposit Protection Scheme and has Professional Indemnity
						Insurance.
					</p>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
