import React from 'react';

const ContractHolders: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Contract Holder Fees
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-12">
					{/* Fees Section */}
					<h3 className="text-3xl font-semibold">
						Contract Holders Payments in Default
					</h3>
					<section>
						<div className="overflow-x-auto">
							<table className="min-w-full table-auto">
								<thead>
									<tr>
										<th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
											Fee Type
										</th>
										<th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
											Description
										</th>
									</tr>
								</thead>
								<tbody className="text-sm text-gray-600">
									{fees.map((fee, index) => (
										<tr key={index} className="border-b">
											<td className="px-4 py-2 font-medium text-gray-800">
												{fee.title}
											</td>
											<td className="px-4 py-2">{fee.description}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</section>

					{/* Note Section */}
					<p className="mt-6 text-gray-700">
						<em>
							* Please note that more information on the above can be found
							within the Occupation Contract.
						</em>
					</p>
				</div>
			</div>
		</div>
	);
};

export default ContractHolders;

// Data for fees
const fees = [
	{
		title: 'Interest on Rent Arrears and Other Monies',
		description:
			'Interest may be charged on overdue rent and any other outstanding monies owed by the Contract Holder, as specified in the Occupation Contract. This encourages timely payments and helps cover the costs associated with late payments.',
	},
	{
		title: 'Missed Appointments',
		description:
			'If a contractor is scheduled to perform remedial work and the Contract Holder refuses entry or is not present to allow entry, resulting in charges to the landlord. These costs may be passed on to the Contract Holder.',
	},
	{
		title: 'Avoidable or Purposeful Damage to Property',
		description:
			'Damage caused by neglect, careless, or willful behavior by the Contract Holder. The Contract Holder is responsible for repair or replacement costs due to such damage.',
	},
	{
		title: 'Replacement Keys',
		description:
			'Costs associated with lost keys requiring the landlord to arrange for new keys and delivery to the Contract Holder.',
	},
	{
		title: 'Emergency/Out of Hours Call-Out Fees',
		description:
			'Fees incurred when the landlord arranges for emergency services at the Contract Holder’s request, especially if the issue was caused by the Contract Holder (e.g., intentional damage or locked out). These fees may be charged to the Contract Holder.',
	},
	{
		title: 'Insufficient Funds to Cover Costs from Deposit',
		description:
			'If deductions from the deposit do not fully cover damages or fees owed at the end of the Occupation Contract, the Contract Holder is responsible for paying any outstanding amounts directly.',
	},
];
