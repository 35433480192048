import React from 'react';

const EqualityAndDiversityPolicy: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Equality and Diversity Policy
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-6">
					<p>
						Rochefort Shugar Ltd. is committed to treating all people equally
						and with respect irrespective of their age, disability, gender
						reassignment, marriage or civil partnership, pregnancy or maternity,
						race, religion or belief, sex, or sexual orientation.
					</p>
				</div>
			</div>
		</div>
	);
};

export default EqualityAndDiversityPolicy;
