import React from 'react';
import { FaCheckCircle, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import coverPhoto from '../assets/cardiff_bay_cityscape.jpg'; // Import your local image
import logo from '../assets/logo-rochefort-shugar-upscale.jpeg';

const Home: React.FC = () => {
	return (
		<div>
			{/* Hero Section with Cover Photo and Call-to-Action */}
			<div
				className="relative w-full h-[85vh] bg-cover bg-center flex items-center"
				style={{
					backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url(${coverPhoto})`,
				}}
			>
				{/* Container for text and buttons */}
				<div className="max-w-2xl mx-auto text-center px-6 md:px-8">
					{/* Logo with styling */}
					<img
						src={logo}
						alt="Company logo"
						className="mx-auto mb-6 w-64 md:w-80"
					/>
					<p className="text-white text-lg md:text-2xl mb-8">
						Providing personalized and comprehensive services since 1996.
					</p>
					{/* Call to Action Buttons */}
					<div className="flex flex-col md:flex-row justify-center items-center gap-4">
						<a
							href="https://www.zoopla.co.uk/to-rent/branch/rochefort-shugar-cardiff-43341"
							target="_blank"
							rel="noopener noreferrer"
							className="px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition duration-300"
						>
							View Properties
						</a>
						<Link
							to="/contact-us"
							className="px-6 py-3 text-white bg-green-600 hover:bg-green-700 rounded-lg transition duration-300"
						>
							Get in Touch
						</Link>
					</div>
				</div>
				{/* Scroll Indicator */}
				<div className="absolute bottom-6 left-1/2 transform -translate-x-1/2">
					<HashLink smooth to="#services">
						<FaChevronDown className="animate-bounce text-white w-8 h-8 cursor-pointer" />
					</HashLink>
				</div>
			</div>

			{/* Services Section */}
			<section id="services" className="py-16 bg-gray-200">
				<div className="max-w-7xl mx-auto px-6 md:px-8">
					<h2 className="text-3xl font-semibold text-center mb-12">
						Our Comprehensive Services
					</h2>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
						{services.map((service, index) => (
							<div
								key={index}
								className="bg-white shadow-md rounded-lg p-6 text-center"
							>
								<FaCheckCircle className="text-blue-600 w-12 h-12 mx-auto mb-4" />
								<h3 className="text-xl font-semibold mb-2">{service.title}</h3>
								<p className="text-gray-600">{service.description}</p>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Contact Section */}
			<section className="py-16 bg-blue-600">
				<div className="max-w-7xl mx-auto px-6 md:px-8 text-center text-white">
					<h2 className="text-3xl font-semibold mb-4">
						Ready to Work With Us?
					</h2>
					<p className="text-lg mb-8">
						Contact us today to discuss how we can assist with your property
						management needs.
					</p>
					<Link
						to="/contact-us"
						className="inline-block px-8 py-4 bg-white text-blue-600 font-semibold rounded-lg hover:bg-gray-100 transition duration-300"
					>
						Contact Us
					</Link>
				</div>
			</section>
		</div>
	);
};

export default Home;

// Data for services
const services = [
	{
		title: 'Property Appraisal',
		description:
			'We evaluate your property to determine the optimal rental value.',
	},
	{
		title: 'Marketing & Advertising',
		description:
			'We market your property via top platforms to reach a wide audience.',
	},
	{
		title: 'Contract Holder Screening',
		description:
			'Comprehensive referencing and credit checks for reliable contract holders.',
	},
	{
		title: '24/7 Maintenance',
		description:
			'We provide round-the-clock property maintenance services for your contract holders.',
	},
	{
		title: 'Legal Compliance',
		description:
			'Assistance and guidance to ensure your property meets all legal requirements.',
	},
	{
		title: 'Financial Reporting',
		description:
			'Detailed income & expense reports for your end-of-year tax returns.',
	},
];
