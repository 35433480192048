import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo-rochefort-shugar.jpg'; // Replace with your actual logo path

const NavBar: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Enable smooth scrolling
		});
		setIsOpen(false);
	}, [pathname]);

	return (
		<nav className={`bg-gray-800 text-white p-4 w-full`}>
			<div className="container mx-auto flex justify-between items-center">
				{/* Logo */}
				<Link
					to="/"
					className="hover:text-blue-500"
					onClick={() => setIsOpen(false)}
				>
					<img src={logo} alt="Company Logo" className="h-10" />
				</Link>

				{/* Links for Desktop */}
				<ul className="hidden lg:flex flex-grow justify-center space-x-8">
					<li>
						<Link
							to="/landlords"
							className="hover:text-blue-500 text-lg px-3 py-2"
						>
							Landlords
						</Link>
					</li>
					<li>
						<Link
							to="/contract-holders"
							className="hover:text-blue-500 text-lg px-3 py-2"
						>
							Contract Holders
						</Link>
					</li>
					<li>
						<Link
							to="/about-us"
							className="hover:text-blue-500 text-lg px-3 py-2"
						>
							About Us
						</Link>
					</li>
					<li>
						<Link
							to="/contact-us"
							className="hover:text-blue-500 text-lg px-3 py-2"
						>
							Contact Us
						</Link>
					</li>
				</ul>

				{/* Hamburger Icon for Mobile */}
				<div className="lg:hidden">
					<button
						onClick={toggleMenu}
						className="focus:outline-none flex flex-col space-y-1"
					>
						{/* Top bar */}
						<span
							className={`block h-0.5 w-6 bg-white transition-transform duration-300 ease-in-out ${
								isOpen ? 'rotate-45 translate-y-2' : ''
							}`}
						/>
						{/* Middle bar (hidden when open) */}
						<span
							className={`block h-0.5 w-6 bg-white transition-opacity duration-300 ease-in-out ${
								isOpen ? 'opacity-0' : 'opacity-100'
							}`}
						/>
						{/* Bottom bar */}
						<span
							className={`block h-0.5 w-6 bg-white transition-transform duration-300 ease-in-out ${
								isOpen ? '-rotate-45 -translate-y-2' : ''
							}`}
						/>
					</button>
				</div>
			</div>

			{/* Mobile Menu */}
			<div
				className={`lg:hidden transition-all duration-500 ease-in-out overflow-hidden ${
					isOpen ? 'max-h-96' : 'max-h-0'
				}`}
			>
				<ul className="bg-gray-700 mt-4 p-4 font-bold">
					<li>
						<Link to="/landlords" className="block py-2 hover:bg-gray-600">
							Landlords
						</Link>
					</li>
					<li>
						<Link
							to="/contract-holders"
							className="block py-2 hover:bg-gray-600"
						>
							Contract Holders
						</Link>
					</li>
					<li>
						<Link to="/about-us" className="block py-2 hover:bg-gray-600">
							About Us
						</Link>
					</li>
					<li>
						<Link to="/contact-us" className="block py-2 hover:bg-gray-600">
							Contact Us
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default NavBar;
